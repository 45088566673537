import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../../../../../components/Layout';
import Seo from '../../../../../components/SEO';
import QuestionSection from '../../../../../components/QuestionSection';
import {ConnectorIntro, SeeAllArrow} from '../../../../../components/systems';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const Azure = () => {
  const connectorName = 'azure';
  const connectorImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/systems/azure/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        edges {
          node {
            name
            relativePath
            base
            childImageSharp {
              gatsbyImageData(width: 1140, layout: CONSTRAINED, placeholder: BLURRED, quality: 100, formats: AUTO)
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const edgesImages = connectorImages.allFile.edges;
  const images = {
    overview: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-overview`)[0]?.node,
    featureOne: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-01`)[0]?.node,
    featureTwo: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-02`)[0]?.node,
    logo: edgesImages.filter((edge) => edge.node.name === `${connectorName}`),
  };

  const connector = {
    intro: {
      logo: images.logo,
      name: 'Azure',
      description: `<u>Microsoft Azure</u> is a cloud platform for developing and deploying applications, data, and compute resources, as well as for running Microsoft applications globally. Azure AD provides the access management framework for all users, resources, and systems running on the platform. Azure AD is similar to Microsoft Active Directory (AD), but doesn’t use hierarchical naming structure or the protocol found in the Lightweight Directory Access Protocol (LDAP) standard. Instead, Azure offers a flat name space for all user accounts and uses groups and roles to manage user access to resources. Also, Azure AD provides Service Accounts for non-human users, such as applications.`,
    },
  };
  const {intro} = connector;

  return (
    <Layout
      seo={
        <Seo
          title="Azure System | Trustle"
          description="The Trustle connector for Microsoft Azure provides both the ability to manage access to Azure cloud resources and manage Azure AD resources such as users, groups, roles, and service accounts."
        />
      }
    >
      <section className="pt-16 overflow-hidden transform bg-gradient-to-b from-gray-200 via-white to-gray-200">
        <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
          <SeeAllArrow />
        </div>

        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center">
          {connector && (
            <>
              <ConnectorIntro name={intro?.name} description={intro?.description} logo={intro?.logo} isPageHeader />

              <div className="m-auto mb-16 text-3xl font-bold text-center leading-11 md:leading-12 md:text-5xl text-blue">
                Trustle w/ Azure
              </div>

              {/* to do: create this as component */}

              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col items-center justify-between xl:flex-row">
                  <div className="">
                    <div className="max-w-[640px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">Overview</h2>
                      <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                        The Trustle connector for Microsoft Azure provides both the ability to manage access to Azure
                        cloud resources and manage Azure AD resources such as users, groups, roles, and service
                        accounts. System owners and provisioners can view the dashboard, which provides an overall
                        health check of your Azure platform in the form of a “Trustle Score,” as well as a full list of
                        accounts on Azure, their exposure to risks, and recommendations on how to remediate or avoid
                        such risks.<br></br>
                        <br></br> System owners and provisioners also can see a full list of resources available on the
                        Azure instance and specify which of these should appear (and therefore be requestable) by users.
                        Trustle provides defaults, but these admins can also specify the sensitivity of Azure resources,
                        which alters the approval routing workflows and context queues accordingly.
                      </p>
                    </div>
                  </div>
                  <div className="pb-8 md:pb-0 max-w-[600px]">
                    {images?.overview && (
                      <GatsbyImage
                        fluid={images.overview.fluid}
                        alt="dashboard image"
                        image={getImage(images.overview)}
                        className="max-w-[560px]"
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">Identity Management in Azure</h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  Microsoft recommends managing access to Azure resources through its Role Based Access Control (RBAC)
                  feature and through group membership; in fact, Microsoft documentation reads, “When you plan your
                  access control strategy, grant users the least privilege required to get their work done.” Enabling
                  this principle of least privilege requires familiarity with the platform, sensitivity of the services
                  offered, and the ability to monitor and remediate changes that can take your Azure instance out of
                  policy.<br></br>
                  <br></br>For example, Azure has several defined roles, such as Owner and Contributor, which should be
                  reserved only for those users whose job it is to manage the Azure instance at that level. Other roles
                  create much less exposure on the system. Azure also provides the ability for your organization to
                  create custom roles–something the Trustle connector also picks up on and manages—but the additional
                  roles can make administration more difficult. And of course, in non-production environments, it may be
                  acceptable for non-ops personnel to have be an Owner, but this can become complicated to manage as the
                  number of Azure environments grows in your organization.<br></br>
                  <br></br> Trustle enables system owners of all environments—whether Test, Dev, Prod, or otherwise—to
                  manage their permissions and instances in a distributed, yet coordinated manner. This enables
                  deployment pipelines to be continuously secure and monitored, while keeping administration overhead
                  low for any given person.
                </p>
              </div>

              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col items-center justify-between xl:flex-row">
                  <div className="pb-8 md:pb-0 max-w-[630px]">
                    {images?.featureOne && (
                      <GatsbyImage
                        fluid={images.featureOne.fluid}
                        alt="dashboard image"
                        image={getImage(images.featureOne)}
                        className="max-w-[630px] drop-shadow-xl md:drop-shadow-2xl mb-8"
                      />
                    )}
                  </div>
                  <div className="pb-8 ">
                    <div className="max-w-[540px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">
                        Baseline Management of Accounts
                      </h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Microsoft identity management has long distinguished between human users and service accounts,
                        and Azure continues in this tradition. In addition, Azure provides robust RBAC features that
                        Trustle helps you harness.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">User Accounts</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Trustle discovers all the users on Azure, as well as profile data for those accounts. This helps
                        you link accounts to Trustle users and manage access with precision.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">System Accounts</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Trustle detects system accounts on Azure and helps manage their access and visibility.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">Roles</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Trustle discovers all roles—including custom roles—and enables you to manage membership in those
                        roles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="m-auto mb-16 text-3xl font-bold text-center leading-11 md:leading-12 md:text-5xl text-blue">
                Managing Access to Azure Resources
              </div>

              {/* To do: update to component */}
              <div className="mx-8">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">Operationalize Azure Security </h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  Microsoft provides many frameworks for managing access to cloud resources, but this essentially adds
                  up to good advice—the real work is left to the customer to implement. This is where Trustle comes in:
                  Trustle helps you organize and operationalize good security practices. Trustle helps you right-size
                  the access of your service accounts and enables you to move infrequently used resources to Temporary
                  or Just-in-Time access. This means that users who need infrequent access to valuable assets and data
                  can gain that access easily, while making their accounts less useful or attractive to attackers.
                </p>
                <div className="pb-8 mb-8">
                  {images?.featureTwo && (
                    <GatsbyImage
                      fluid={images.featureTwo.fluid}
                      alt="dashboard"
                      image={getImage(images.featureTwo)}
                      className="mb-8 drop-shadow-xl md:drop-shadow-2xl"
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <QuestionSection />
    </Layout>
  );
};
export default Azure;
